<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("IMPORTS.ADD_IMPORT") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <ImportForm
              :loading="loading"
              :importData="importation"
              :formErrors="formErrors"
              @importSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultImport from "./defaultImport";
import ImportForm from "@/views/Pages/AdminModule/ImportModule/Partials/ImportForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ImportForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      importation: cloneDeep(defaultImport),
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Imports" });
    },

    viewImport(importation) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Imports",
        params: { id: importation.id },
      });
    },

    async handleSubmit(importation) {
      this.loading = true;
      const importationData = cloneDeep(importation);
      try {
        await this.$store.dispatch("files/upload", {
          image: importationData.file,
          ressource_name: "imports",
          ressource_id: 0,
          field: "file",
        });
        const file_url = await this.$store.getters["files/url"];
        importationData.file_url = file_url;

        await this.$store.dispatch("imports/add", importationData);
        this.$notify({
          type: "success",
          message: this.$t("IMPORTS.IMPORT_ADDED"),
        });
        const importation = await this.$store.getters["imports/import"];
        this.viewImport(importation);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
